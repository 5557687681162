import React, { Component } from "react";

class ScheduleRetakeBtn extends Component {
  offerLinkRedirect = () => {
    let redirectUrl = "";
    if (this.props.quizofferbtnlink) {
      redirectUrl = this.props.quizofferbtnlink;
    } else {
      redirectUrl = window.location.href;
    }
    window.open(redirectUrl, "_blank");
  };
  render() {
    const {
      theme_colors,
      quizofferbtntxt,
      retake_this_quiz_text,
      restartQuiz,
      welcome_message_color,
      selectFont,
    } = this.props;
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="quiz_wrap__btn mb-4 ql-editor">
            <div className="quiz_ans__btn">
              <button
                type="button"
                className={"p-0 mx-auto " + (selectFont ? selectFont : "")}
                onClick={this.offerLinkRedirect}
                style={{
                  background: theme_colors
                    ? theme_colors.button_color
                    : welcome_message_color,
                  color: theme_colors ? theme_colors.button_color_text : "",
                }}
              >
                {quizofferbtntxt ? quizofferbtntxt : "Schedule Now"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="quiz_wrap__btn mb-4 ql-editor">
            <div className="quiz_ans__btn">
              <button
                type="button"
                className={"p-0 mx-auto " + (selectFont ? selectFont : "")}
                onClick={() => {
                  restartQuiz("start");
                }}
                style={{
                  background: theme_colors.button_color
                    ? theme_colors.button_color
                    : welcome_message_color,
                  color: theme_colors ? theme_colors.button_color_text : "",
                }}
              >
                {retake_this_quiz_text
                  ? retake_this_quiz_text
                  : "Retake This Quiz"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ScheduleRetakeBtn;

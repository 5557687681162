/**
 * This is the form for creating / editing questions of a quiz.
 */
import React from "react";
import swal from "sweetalert";

import { checkNull } from "../helper";
import { ImageUpload } from "../components/imageupload";
import { NotificationManager } from "react-notifications";
import ReactTooltip from "react-tooltip";
import CustomTextEditor from "../components/customtexteditor";

export class Queform extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active,
      modaltitle: this.props.modaltitle,
      modalbody: this.props.modalbody,
      ans: [],
      marks: [0, 0, 0, 0],
      img: [],
      personalityArr: [],
      quetype: this.props.quetype,
      anscount: 2,
      maxanscount: 4,
      minanscount: 2,
      anscounter: [1, 2],
      que: this.props.selectedQue,
      quiz: this.props.quiz,
      filepath: "",
    };

    this.onQueTypeSelect = this.onQueTypeSelect.bind(this);
    this.addAns = this.addAns.bind(this);
    this.delAns = this.delAns.bind(this);
    this.changeAnsValue = this.changeAnsValue.bind(this);
    this.changeMarksValue = this.changeMarksValue.bind(this);
    //this.changeAnsImage = this.changeAnsImage.bind(this);
    this.changeQueTitle = this.changeQueTitle.bind(this);
    this.saveAns = this.saveAns.bind(this);
    this.onImageUploadComplete = this.onImageUploadComplete.bind(this);
    this.onImageDeleteComplete = this.onImageDeleteComplete.bind(this);
    this.onPersonalityChanged = this.onPersonalityChanged.bind(this);
    this.handleEditorValueChange = this.handleEditorValueChange.bind(this);
    this.onQueCategoryChanged = this.onQueCategoryChanged.bind(this);
    //this.onQuePersonalityChanged = this.onQuePersonalityChanged.bind(this);
    
  }

  componentWillReceiveProps(nextProps) {
    // console.log("next",nextProps.quiz.personalityArr);
    var quecategory = null;
    var quepersonality = null;
    if (nextProps["selectedQue"] != null) {
      //&&  nextProps['selectedQue'].queid  != null)
      var ansArr = JSON.parse(nextProps["selectedQue"].jsonans);
      if (ansArr != null && ansArr.length != 0) {
        ansArr = ansArr.filter(checkNull);
      }
      var marksArr = JSON.parse(nextProps["selectedQue"].jsonmarks);
      if (marksArr != null && marksArr.length != 0) {
        marksArr = marksArr.filter(checkNull);
      }
      if (marksArr == null || marksArr.length == 0) {
        if (
          nextProps.quiz.quiztype == "qztype2" ||
          nextProps.quiz.quiztype == "qztype7"
        ) {
          marksArr = [
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
          ];
        } if (nextProps.quiz.quiztype == "qztype8") {
          marksArr = [
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
            nextProps.quiz.personalityArr[0].title,
          ];
          if(nextProps.quiz && nextProps.quiz.quecategory){
            quecategory = nextProps.quiz.quecategory.map((item,index)=>item.title);
            if((nextProps.selectedQue.quecategory == null || nextProps.selectedQue.quecategory == '') && quecategory && quecategory.length >0){
              nextProps.selectedQue.quecategory = quecategory[0];
              nextProps.selectedQue.quepersonality = quecategory[0];
            }
          }
        }if (nextProps.quiz.quiztype == "qztype9") {
          if(nextProps.quiz && nextProps.quiz.quecategory){
            quecategory = nextProps.quiz.quecategory.map((item,index)=>item.title);
            if((nextProps.selectedQue.quecategory == null || nextProps.selectedQue.quecategory == '') && quecategory && quecategory.length >0){
              nextProps.selectedQue.quecategory = quecategory[0];
              nextProps.selectedQue.quepersonality = quecategory[0];
            }
          }
          // if(nextProps.quiz && nextProps.quiz.quepersonality){
          //   quepersonality = nextProps.quiz.quepersonality.map((item,index)=>item.title);
          //   if((nextProps.selectedQue.quepersonality == null || nextProps.selectedQue.quepersonality == '') && quecategory && quecategory.length >0){
          //     nextProps.selectedQue.quepersonality = quepersonality[0];
          //   }
          // }

        }

        else {
          marksArr = [0, 0, 0, 0];
        }
      }
      var imgArr = JSON.parse(nextProps["selectedQue"].jsonansimg);

      var personalityArr = marksArr; //JSON.parse(nextProps['selectedQue'].jsonpersonality);

      var anscounter = ansArr.map((element, index) => {
        return index + 1;
      });

      var maxanscount = 4;
      if (nextProps["selectedQue"].quetype == "TF") {
        maxanscount = 2;
      }

      this.setState({
        anscount: ansArr.length,
        anscounter,
        maxanscount,
        ans: ansArr,
        quecategory,
        quepersonality,
        marks: marksArr,
        img: imgArr,
        personalityArr,
        que: nextProps["selectedQue"],
        quiz: nextProps["quiz"],
        active: nextProps["active"],
        //quetype:nextProps['selectedQue'].quetype
        quetype: nextProps["quetype"],
      });
    } else {
      var tempmarksarr = [0, 0, 0, 0];
      this.setState({
        active: nextProps["active"],
        anscount: 2,
        ans: ["", ""],
        marks: tempmarksarr,
        img: [],
        maxanscount: 4,
        minanscount: 2,
        que: nextProps["selectedQue"],
      });
    }

    // this.setState({active:nextProps['active']});
  }

  changeQueTitle(event) {
    var quetitle = event.target.value;
    var tempQue = this.state.que;
    var self = this;
    if (tempQue == null) {
      tempQue = {
        action: "",
        jsonans: "",
        jsonansimg: "",
        jsonmarks: "",
        queid: null,
        queimg: "",
        quizid: "",
        title: "",
      };
    }
    tempQue.title = quetitle;
    this.setState({ que: tempQue }, () => {
      self.props.updateQue(tempQue);
    });
  }
  handleEditorValueChange(event) {
    var self = this;
    var tempQue = this.state.que;
    tempQue.title = event.target.value;
    this.setState({ que: tempQue }, () => {
      self.props.updateQue(tempQue);
    });
  }

  onQueTypeSelect(event) {
    if (this.state.que.queid) {
      if (this.state.quiz.quizstatus == "published") {
        //swal("Sorry !","You can not change question type in an published quizz", "warning");
        NotificationManager.error(
          "Error",
          "You can not change question type in a published quiz."
        );
        return false;
      }
    }
    var anscount = 4;
    var ansArr = this.state.ans;
    var self = this;
    if (event.target.value == "TF") {
      anscount = 2;
      ansArr[0] = "TRUE";
      ansArr[1] = "FALSE";
    } else {
      ansArr[0] = ansArr[1] = "";
    }
    ansArr.splice(2);

    var tempQue = this.state.que;

    tempQue.quetype = event.target.value;
    tempQue.jsonans = JSON.stringify(ansArr);

    this.setState(
      {
        que: tempQue,
        quetype: event.target.value,
        maxanscount: anscount,
        anscount: anscount,
        ans: ansArr,
      },
      () => {
        self.props.updateQue(tempQue);
      }
    );
  }

  onQueCategoryChanged(event) {
    var quecategory = event.target.value;
    var tempque = this.state.que;
    tempque["quecategory"] = quecategory;
    tempque['quepersonality'] = quecategory;
    this.setState({ que: tempque, quecategory }, () => {
      this.props.updateQue(tempque);
    });
  }

  // onQuePersonalityChanged(event) {
  //   var quepersonality = event.target.value;
  //   var tempque = this.state.que;
  //   tempque["quepersonality"] = quepersonality;
  //   this.setState({ que: tempque, quepersonality }, () => {
  //     this.props.updateQue(tempque);
  //   });
  // }

  saveAns(event, flag) {
    // check if question title empty
    if (this.state.que.title == "") {
      NotificationManager.error("Error", "Question can not be empty");
      return false;
    }
    // check if ans empty
    let stopNextProcess = false;
    this.state.ans.map((ansitem) => {
      if (ansitem == "") {
        NotificationManager.error("Error", "Ans can not be empty");
        stopNextProcess = true;
        return false;
      }
    });

    // Stop next process if any answer is empty
    if (stopNextProcess == true) {
      return false;
    }

    var markflag = this.checkMarksValue();

    if (this.state.quiz != null) {
      if (this.state.quiz.quiztype == "qztype1" || this.state.quiz.quiztype == "qztype5" || this.state.quiz.quiztype == "qztype6" || this.state.quiz.quiztype == "qztype9") {
        if (markflag == true) {
          var tempque = this.state.que;
          if (this.state.quetype == "TF") {
            var ansArr = this.state.ans;
            ansArr[0] = "TRUE";
            ansArr[1] = "FALSE";
            ansArr[2] = ansArr[3] = null;
            this.setState({ ans: ansArr });
          }

          tempque["jsonans"] = JSON.stringify(this.state.ans);
          tempque["jsonmarks"] = JSON.stringify(this.state.marks);
          tempque["jsonansimg"] = JSON.stringify(this.state.img);
          tempque["quetype"] = this.state.quetype;
          var tempans = null;
          var tempmarks = [0, 0, 0, 0];
          var tempimg = [];
          this.setState({
            que: tempque,
            ans: tempans,
            marks: tempmarks,
            img: tempimg,
          });

          this.props.saveQue(event, tempque, flag);
        } else {
          //swal("", "please select atleast one correct answer", "info");
          NotificationManager.error(
            "Validation Error",
            "Please select atleast one correct answer"
          );
        }
      }
      if (
        this.state.quiz.quiztype == "qztype2" ||
        this.state.quiz.quiztype == "qztype7"
      ) {
        var tempque = this.state.que;
        tempque["jsonans"] = JSON.stringify(this.state.ans);
        //tempque['jsonmarks'] = JSON.stringify(this.state.marks);
        tempque["jsonansimg"] = JSON.stringify(this.state.img);
        //tempque['jsonpersonality'] = JSON.stringify(this.state.personalityArr);

        tempque["jsonmarks"] = JSON.stringify(this.state.personalityArr);
        tempque["quetype"] = this.state.quetype;
        var tempans = null;
        var tempmarks = [0, 0, 0, 0];
        var tempimg = [];
        this.setState({
          que: tempque,
          ans: tempans,
          marks: tempmarks,
          img: tempimg,
        });

        this.props.saveQue(event, tempque, flag);
      }
      if (this.state.quiz.quiztype == "qztype8") {
        var tempque = this.state.que;
        tempque["jsonans"] = JSON.stringify(this.state.ans);
        //tempque['jsonmarks'] = JSON.stringify(this.state.marks);
        tempque["jsonansimg"] = JSON.stringify(this.state.img);
        //tempque['jsonpersonality'] = JSON.stringify(this.state.personalityArr);

        tempque["jsonmarks"] = JSON.stringify(this.state.personalityArr);
        tempque["quetype"] = this.state.quetype;
        var tempans = null;
        var tempmarks = [0, 0, 0, 0];
        var tempimg = [];
        this.setState({
          que: tempque,
          ans: tempans,
          marks: tempmarks,
          img: tempimg,
        });

        this.props.saveQue(event, tempque, flag);
      }
      if (this.state.quiz.quiztype == "qztype4") {
        var tempque = this.state.que;
        tempque["jsonans"] = JSON.stringify(this.state.ans);
        tempque["jsonmarks"] = JSON.stringify(this.state.marks);
        tempque["jsonansimg"] = JSON.stringify(this.state.img);
        tempque["quetype"] = this.state.quetype;
        var tempans = null;
        var tempmarks = [0, 0, 0, 0];
        var tempimg = [];
        this.setState({
          que: tempque,
          ans: tempans,
          marks: tempmarks,
          img: tempimg,
        });
        this.props.saveQue(event, tempque, flag);
      }
    }
  }

  addAns(event) {
    //var tempquetype = this.state.quetype;
    var counter = this.state.anscount;
    var tempAns = this.state.ans;
    var tempque = this.state.que;
    var anscounter = this.state.anscounter;
    //if (counter < this.state.maxanscount) {
    tempAns[counter] = "";
    anscounter.push(counter);
    counter++;

    tempque["jsonans"] = JSON.stringify(tempAns);
    this.setState(
      {
        anscount: counter,
        ans: tempAns,
        anscounter: anscounter,
        que: tempque,
      },
      () => {
        this.props.updateQue(tempque);
      }
    );
    //}
  }

  delAns(event) {
    var anscount = this.state.anscount;
    var counter = event.target.getAttribute("data-id");
    var tempAns = this.state.ans;
    var tempImg = this.state.img;
    var anscounter = this.state.anscounter;
    var tempque = this.state.que;
    if (anscount > this.state.minanscount) {
      //tempAns.splice((counter -1),1);
      //anscounter.splice((counter-1),1);
      tempAns.splice(counter, 1);
      anscounter.splice(counter, 1);
      tempImg.splice(counter, 1);
      anscount--;

      tempque["jsonans"] = JSON.stringify(tempAns);
      tempque["jsonansimg"] = JSON.stringify(tempImg);
      this.setState(
        {
          anscount: counter,
          ans: tempAns,
          img: tempImg,
          anscounter: anscounter,
          que: tempque,
        },
        () => {
          this.props.updateQue(tempque);
        }
      );
    }
  }

  checkMarksValue() {
    // return true;
    var marksArr = this.state.marks;
    var quetype = this.state.quetype;
    var flag = false;
    if(this.state.quiz.quiztype == 'qztype6' || this.state.quiz.quiztype == 'qztype9'){
      flag = true;
    } else if (marksArr != null && marksArr.length != 0) {
      flag = marksArr.includes(1);
      var filterArr = marksArr.filter((element) => element == 1);
      if (filterArr == null || filterArr.length == 0) {
        flag = false;
      }
      if ((quetype == "MCSA" || quetype == "TF") && filterArr.length > 1) {
        flag = false;
      }
    }

    return flag;
  }

  checkCountOfCorrectAns(dataArr) {
    var filteredArr = dataArr.filter((element) => {
      return element == 1;
    });
    var count = filteredArr == null ? 0 : filteredArr.length;
    return count;
  }

  changeMarksValue(event) {
    var index = event.target.getAttribute("data-id");
    var marksArr = this.state.marks.slice();

    if(this.state.quiz.quiztype=='qztype6' || this.state.quiz.quiztype=='qztype9'){
      marksArr[index] = event.target.value;;
    }
    else{
      marksArr[index] = event.target.checked? 1: this.state.quiz.negativeMarking? -1: 0;
    }

    var count = this.checkCountOfCorrectAns(marksArr);
    var tempque = this.state.que;

    if (
      this.state.quiz.quiztype == "qztype1" &&
      (this.state.que.quetype == "MCSA" || this.state.que.quetype == "TF") &&
      count > 1
    ) {
      //swal("Sorry", "Only one answer can be marked as correct", "info");
      NotificationManager.error(
        "Error",
        "Only one answer can be marked as correct"
      );
      return false;
    } else {
      tempque["jsonmarks"] = JSON.stringify(marksArr);
      this.setState({ que: tempque, marks: marksArr }, () => {
        this.props.updateQue(tempque);
      });
    }
  }

  changeAnsValue(event) {
    var index = event.target.getAttribute("data-id");
    var ansArr = this.state.ans;
    ansArr[index] = encodeURIComponent(event.target.value);

    var tempque = this.state.que;
    if (this.state.quetype == "TF") {
      ansArr[0] = "TRUE";
      ansArr[1] = "FALSE";
      ansArr[2] = ansArr[3] = null;
    }

    tempque["jsonans"] = JSON.stringify(ansArr);
    this.setState({ que: tempque, ans: ansArr }, () => {
      this.props.updateQue(tempque);
    });
  }
  

  onPersonalityChanged(event) {
    var index = event.target.getAttribute("data-id");
    var personalityArr = this.state.personalityArr.slice();
    personalityArr[index] = event.target.value;
    var tempque = this.state.que;
    tempque["jsonmarks"] = JSON.stringify(personalityArr);
    this.setState({ que: tempque, personalityArr }, () => {
      this.props.updateQue(tempque);
    });
  }

  onImageUploadComplete(imgtype, imgid, filepath) {
    if (imgtype == "ansimg") {
      var imgArr = this.state.img;
      var tempque = this.state.que;
      if (imgArr == null) {
        imgArr = [];
      }
      imgArr[imgid] = filepath;
      tempque["jsonansimg"] = JSON.stringify(imgArr);
      this.setState({ que: tempque, img: imgArr }, () => {
        NotificationManager.success(
          "Image Upload",
          "Image uploaded successfully"
        );
        //swal("Image Upload", "Image uploaded successfully", "success");
        this.props.updateQue(tempque);
      });
    } else if (imgtype == "queimg") {
      var selectedQue = this.state.que;
      selectedQue.queimg = filepath;
      this.setState({ que: selectedQue }, () => {
        NotificationManager.success(
          "Image Upload",
          "Image uploaded successfully"
        );
        //swal("Image Upload", "Image uploaded successfully", "success");
        this.props.updateQue(tempque);
      });
    }
    this.setState({
      filepath: filepath,
    });
  }

  onImageDeleteComplete(imgtype, imgid, filepath) {
    if (imgtype == "ansimg") {
      var imgArr = this.state.img;
      var tempque = this.state.que;
      if (imgArr == null) {
        imgArr = [];
      }
      imgArr[imgid] = filepath;
      tempque["jsonansimg"] = JSON.stringify(imgArr);
      this.setState({ que: tempque, img: imgArr }, () => {
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        //swal("Image Delete", "Image deleted successfully", "success");
        this.props.updateQue(tempque);
      });
    } else if (imgtype == "queimg") {
      var selectedQue = this.state.que;
      selectedQue.queimg = filepath;
      this.setState({ que: selectedQue }, () => {
        NotificationManager.success(
          "Image Delete",
          "Image deleted successfully"
        );
        //swal("Image Delete", "Image deleted successfully", "success");
        this.props.updateQue(tempque);
      });
    }
  }


  renderQueCategory() {
    var self = this;
    var quecategory = null;
    if (this.state.quiz != null && this.state.quiz.quecategory) {
      quecategory = this.state.quiz.quecategory;
    }
    if (quecategory != null && quecategory.length > 0) {
      return quecategory.map(function (category, counter) {
        return (
          <option
            key={counter}
            value={category.title}
            selected={
              self.state.quecategory != null &&
              self.state.quecategory[counter] != null &&
              self.state.quiz.quecategory[counter].title == category.title
                ? true
                : false
            }
          >
            {category.title}
          </option>
        );
      }, this);
    } else {
      return null;
    }
  }
  renderQuePersonality() {
    var self = this;
    var quepersonality = null;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      quepersonality = this.state.quiz.personalityArr;
    }
    if (quepersonality != null && quepersonality.length > 0) {
      return quepersonality.map(function (personality, counter) {
        return (
          <option
            key={counter}
            value={personality.title}
            selected={
              self.state.quepersonality != null &&
              self.state.quepersonality[counter] != null &&
              self.state.quiz.personalityArr[counter].title == personality.title
                ? true
                : false
            }
          >
            {personality.title}
          </option>
        );
      }, this);
    } else {
      return null;
    }
  }

  renderPersonalities() {
    var self = this;
    var personalityArr = null;
    if (this.state.quiz != null && this.state.quiz.personalityArr) {
      personalityArr = this.state.quiz.personalityArr;
    }
    if (personalityArr != null && personalityArr.length > 0) {
      return personalityArr.map(function (personality, counter) {
        return (
          <option
            key={counter}
            value={personality.title}
            selected={
              self.state.personalityArr != null &&
              self.state.personalityArr[counter] != null &&
              self.state.quiz.personalityArr[counter].title == personality.title
                ? true
                : false
            }
          >
            {personality.title}
          </option>
        );
      }, this);
    } else {
      return null;
    }
  }


  renderAns() {
    var content = "";
    var tempquetype = this.state.que == null ? "MCSA" : this.state.que.quetype;
    var { ans, marks, img } = this.state;
    var readOnly = false;
    if (tempquetype == "TF") {
      if (ans == null) {
        ans = [];
      }
      ans[0] = true.toString().toUpperCase();
      ans[1] = false.toString().toUpperCase();
      readOnly = true;
    }
    var queid =
      this.state.que == null
        ? 0
        : this.state.que.queid == null
        ? this.state.quiz == null
          ? 0
          : this.state.quiz.que == null
          ? 0
          : this.state.quiz.que.length
        : this.state.que.queid;

    content = (
      <div
        id="ansGrid"
        className={`m-t-md quizansgrid ${
          this.state.quiz != null && this.state.quiz.quiztype == "qztype1"
            ? "gradedquizansgrid"
            : null
        }`}
      >
        <div className="col-md-12" style={{ paddingLeft: 0 }}>
          <div className="col-md-2 col-sm-2 col-xs-12">
            {this.state.quiz != null &&
            (this.state.quiz.quiztype == "qztype2" || this.state.quiz.quiztype == "qztype7" || this.state.quiz.quiztype == "qztype8") ? (
              <div className="Sign-In-Label PersonalityQuiz" style={{ marginTop: 5 }}>
                {" "}
                Personality{" "}
              </div>
            ) : (this.state.quiz != null &&
              (this.state.quiz.quiztype == "qztype6" || this.state.quiz.quiztype == 'qztype9'))?
              (<div className="Sign-In-Label GradedQuiz" style={{ marginTop: 5 }}>
              {" "}
              Weight
            </div>):(<div className="Sign-In-Label GradedQuiz" style={{ marginTop: 5 }}>
                {" "}
                Correct
              </div>
            )}
          </div>
          <div className="col-md-10 col-sm-10 col-xs-12 conte">
            <ReactTooltip />
            <div className="Sign-In-Label" style={{ marginTop: 5 }}>
              Enter Your Answer{" "}
              <i
                data-tip="Preferred image size 382x178"
                className="fa fa-info-circle"
              ></i>
            </div>
          </div>
        </div>
        {this.state.anscounter.map((element, index) => {
          return (
            <div
              className="col-md-12 ans-wrapper-modify"
              key={index}
              style={{ paddingLeft: 0 }}
            >
              <div id="divans1" className="">
                <div
                  style={{ color: "red", fontSize: 12 }}
                  id={"anserror" + index + 1}
                ></div>
                <div className="quiz-question-ans-wrapper">
                  <div
                    className="col-md-2 col-sm-2 col-xs-12"
                    style={{ height: 50, marginTop: 0 }}
                  >
                    {this.state.quiz != null &&
                    (this.state.quiz.quiztype == "qztype2" || this.state.quiz.quiztype == "qztype7" || this.state.quiz.quiztype == "qztype8") ? (
                      <select
                        tabIndex="-98"
                        title="Category"
                        style={{ marginTop: 8 }}
                        className="form-control Sign-In-TextBox personalitySelect"
                        selectpicker=""
                        value={
                          this.state.personalityArr != null &&
                          this.state.personalityArr[index] != null
                            ? this.state.personalityArr[index]
                            : null
                        }
                        onChange={this.onPersonalityChanged}
                        data-id={index}
                        name="personality"
                      >
                        {this.renderPersonalities()}
                      </select>
                    ) : (this.state.quiz != null &&
                      (this.state.quiz.quiztype == "qztype6" || this.state.quiz.quiztype == 'qztype9'))?(<input
                        type="text"
                        maxLength="60"
                        readOnly={readOnly}
                        className="Sign-In-TextBox"
                        id={"weight" + index + 1}
                        onChange={this.changeMarksValue}
                        data-id={index}
                        value={
                          marks == null
                            ? ""
                            : marks[index] == null
                            ? ""
                            : decodeURIComponent(marks[index])
                        }
                        placeholder="Enter Weights for Answer"
                      />
                    ) : (
                      <label className="switch">
                        <input
                          className="GradedQuizSwitch"
                          type="checkbox"
                          maxLength="50"
                          onChange={this.changeMarksValue}
                          data-id={index}
                          checked={
                            marks == null
                              ? false
                              : marks[index] == 1
                              ? true
                              : false
                          }
                        />
                        <span className="slider round"></span>
                      </label>
                    )}
                  </div>
                  <div className="col-md-9 col-sm-10 col-xs-12 ans-option-list">
                    <input
                      type="text"
                      maxLength="200"
                      //value="1"
                      readOnly={readOnly}
                      className="Sign-In-TextBox"
                      id={"ans" + index + 1}
                      onChange={this.changeAnsValue}
                      data-id={index}
                      value={
                        ans == null
                          ? ""
                          : ans[index] == null
                          ? ""
                          : decodeURIComponent(ans[index])
                      }
                      placeholder="Enter Your Answer"
                    />
                    <div className="counter-wrapper pull-right">
                      <span>
                        {ans[index] != null || ""
                          ? decodeURIComponent(ans[index]).length
                          : 0}
                        /200
                      </span>
                    </div>
                  </div>
                  <div className="quiz-ans-control-wrapper">
                    {this.state.quetype == "TF" ? null : (
                      <div
                        className="col-md-1 col-xs-12"
                        style={{ height: 50, marginTop: 0 }}
                      >
                        <div className="input-group-btn imgpreview-modify">
                          <ImageUpload
                            basepath={this.props.basepath}
                            userid={this.props.user.userid}
                            quizid={
                              this.state.quiz != null
                                ? this.state.quiz.quizid
                                : null
                            }
                            queid={queid}
                            imgtype="ansimg"
                            ansid={index}
                            imagepath={
                              img == null || img[index] == null
                                ? null
                                : img[index]
                            }
                            onImageUploadComplete={this.onImageUploadComplete}
                            imagePreviewFlag={true}
                            uploaded_uri=""
                            onImageDeleteComplete={this.onImageDeleteComplete}
                          />
                        </div>
                      </div>
                    )}
                    {this.state.anscount > this.state.minanscount ? (
                      <div
                        className="col-md-1 ans-del"
                        style={{ height: 50, marginTop: 10, marginLeft: 25 }}
                      >
                        <div
                          className="input-group-btn add-input-group"
                          onClick={this.delAns}
                          data-id={index}
                        >
                          <div
                            className="upload-btn upload-btn-ans"
                            data-id={index}
                            style={{
                              paddingTop: 0,
                              paddingRight: 7,
                              paddingBottom: 0,
                              paddingLeft: 7,
                            }}
                          >
                            <i className="fa fa-trash fa-x" data-id={index}></i>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    return content;
  }

  render() {
    var queid =
      this.state.que == null
        ? 0
        : this.state.que.queid == null
        ? this.state.quiz == null
          ? 0
          : this.state.quiz.que == null
          ? 0
          : this.state.quiz.que.length
        : this.state.que.queid;
    return (
      <div
        className={`${this.state.active ? "" : "hide"}`}
        style={{ backgroundColor: "white" }}
      >
        <div className="col-md-12">
          <div className="">
            <div className="qzpanel-body">
              <div className="col-md-12">
                <div className="row">
                  <div className="form-group aaa">
                    {this.state.quiz != null &&
                    this.state.quiz.quiztype == "qztype1" ? (
                      <div>
                        <div className="Sign-In-Label" id="lblquetype">
                          Select Question Type
                        </div>
                        <select
                          id="quetype"
                          className="form-control m-b Sign-In-TextBox"
                          onChange={this.onQueTypeSelect}
                          value={this.state.quetype}
                        >
                          <option value="MCSA">Single Answer Correct</option>
                          {/* <option value="MCMA">Multiple Answer Correct</option> */}
                          <option value="TF">True-False</option>
                        </select>
                      </div>
                    ) : null}
                    {this.state.quiz != null &&
                    (this.state.quiz.quiztype == "qztype8" || this.state.quiz.quiztype == "qztype9")? (
                      <div style={{ marginLeft: "-22px" }}>
                        <div className="Sign-In-Label" id="lblquetype">
                          Select Question Category
                        </div>
                        <select
                          id="quetype"
                          className="form-control m-b Sign-In-TextBox"
                          onChange={this.onQueCategoryChanged}
                          value={this.state.que? this.state.que.quecategory : null}
                        >
                          {this.renderQueCategory()}
                        </select>
                      </div>
                    ) : null}
                    {/* {this.state.quiz != null &&
                    this.state.quiz.quiztype == "qztype9" ? (
                      <div style={{ marginLeft: "-22px" }}>
                        <div className='col-md-12' >
                          <div className="Sign-In-Label" id="lblquetype">
                            Select Question Category
                          </div>
                          <select
                            id="quetype"
                            className="form-control m-b Sign-In-TextBox"
                            onChange={this.onQueCategoryChanged}
                            value={this.state.que? this.state.que.quecategory : null}
                          >
                            {this.renderQueCategory()}
                          </select>
                        </div>
                        <div className='col-md-6'>
                          <div className="Sign-In-Label" id="lblquetype">
                            Select Personality
                          </div>
                          <select
                            id="quetype"
                            className="form-control m-b Sign-In-TextBox"
                            onChange={this.onQuePersonalityChanged}
                            value={this.state.que? this.state.que.quepersonality : null}
                          >
                            {this.renderQuePersonality()}
                          </select>
                        </div>
                      </div>
                    ) : null} */}
                    
                    <div
                      className="Sign-In-Label"
                      style={{ transform: "translateX(-18px)" }}
                      id="lblque"
                    >
                      Question{" "}
                      <i
                        data-tip="Preferred image size 1920x540"
                        className="fa fa-info-circle"
                      ></i>
                    </div>
                    <div
                      style={{ color: "red", fontSize: 12 }}
                      id="quizError"
                    ></div>
                    <div className="input-group quiz-question-m">
                    <input
                      type="text"
                      maxLength="250"
                      className="Sign-In-TextBox"
                      onChange={this.handleEditorValueChange}
                      value={
                        this.state.que == null
                          ? ""
                          : this.state.que.title == null
                          ? ""
                          : this.state.que.title
                      }
                      placeholder={"Enter Your Question"}
                    />
                      <div className="counter-wrapper question-counter-modify">
                        <span>
                          {this.state.que && this.state.que.title
                            ? this.state.que.title.length
                            : "0"}
                          /250
                        </span>
                      </div>
                      <div className="input-group-btn imgpreview-modify">
                        {/* <div className="btn no-border"><i className="fa fa-image fa-2x"></i></div> */}
                        <ImageUpload
                          basepath={this.props.basepath}
                          userid={this.props.user.userid}
                          quizid={
                            this.state.quiz != null
                              ? this.state.quiz.quizid
                              : null
                          }
                          queid={queid}
                          imgtype="queimg"
                          imagepath={
                            this.state.que == null ||
                            this.state.que.queimg == null
                              ? null
                              : this.state.que.queimg
                          }
                          onImageUploadComplete={this.onImageUploadComplete}
                          uploaded_uri=""
                          imagePreviewFlag={true}
                          onImageDeleteComplete={this.onImageDeleteComplete}
                        />
                      </div>
                    </div>
                    {/* <ImageUpload basepath={this.props.basepath} userid={this.props.user.userid} 
                      quizid={this.state.quiz.quizid} queid={this.state.que==null?null:this.state.que.queid} imgtype='queimg' title='Add Que Image' imagepath={(this.state.que== null || this.state.que.queimg==null)?null:this.state.que.queimg} uploaded_uri='' onImageUploadComplete={this.onImageUploadComplete}/> */}
                    {this.renderAns()}
                    <div className="row col-md-12">
                      <button
                        id="btnqzansadd"
                        onClick={this.addAns}
                        type="button"
                        className={`btn btn-primary btn-rounded m-t-sm `}
                        style={{ padding: 0, paddingTop: 4 }}
                      >
                        <i className="fa fa-plus fa-2x"></i>
                      </button>
                      {/* <button id="btnqzansdel" onClick={this.delAns} type="button" className={`btn btn-primary m-t-sm ${this.state.anscount==2?'hide':''}`}>Delete Answer</button> */}
                      <hr />
                    </div>
                    <div className="col-md-12 button-mob">
                      <div
                        id="btnquecancel"
                        type="button"
                        className="Sign-In-Button center-block"
                        onClick={this.props.closeQueForm}
                      >
                        Cancel
                      </div>
                      <div
                        id="btnquesave"
                        type="button"
                        className="Sign-In-Button center-block"
                        onClick={(e) => this.saveAns(e, false)}
                        data-id={
                          this.state.que == null ? null : this.state.que.queid
                        }
                      >
                        Save
                      </div>
                      {/* <button id="btnquesaveadd" type="button" className="btn btn-primary m-sm" onClick={(e) =>this.saveAns(e,true)} data-id={this.state.que==null?null:this.state.que.queid}>Save and Add Another</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

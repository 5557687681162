/* 
This is the root file which calls other files to render application components
*/

import React from "react";
import ReactDOM from "react-dom";
import App  from "./App";
import { BrowserRouter, Route } from "react-router-dom";
//import * as serviceWorker from "./serviceWorker";
import 'react-notifications/lib/notifications.css';

/* 
basepath should point to root url of the backend.
there are three local, uat and prod.
at any point of time only one basepath needs to be selected.

*/


var basepath = process.env.REACT_APP_BASEPATH;
var localpath = process.env.REACT_APP_LOCALPATH;

console.log('process env is '+process.env.REACT_APP_ENV);

var facebookloginurl = "https://www.facebook.com/dialog/oauth?client_id=3805076026252317&redirect_uri=https://app.quizzes4leads.com/index.html&state=82e0a58fd9d3051f16d5c8e36c251ab8&sdk=php-sdk-3.2.3&scope=email,public_profile";

if(process.env.NODE_ENV === "production"){
  basepath = "https://app.quizzes4leads.com:8443/";
  localpath = "https://app.quizzes4leads.com/";
}
if(process.env.NODE_ENV === "uat"){
  basepath = "https://uat.quizzes4leads.com:8443/";
  localpath = "https://uat.quizzes4leads.com/";
}
if(process.env.REACT_APP_ENV === "stage"){
  basepath = process.env.REACT_APP_BASEPATH;
  localpath = process.env.REACT_APP_LOCALPATH;;
}

console.log('basepath is '+basepath);

console.log('localpath is '+localpath);

var qzbasepath = basepath + "index.php/quizzer/";

// if(typeof window !== 'undefined'){
//   ReactDOM.hydrate(
//     <BrowserRouter>
//       <Route
//         path="/"
//         component={props => (
//           <App
//             basepath={basepath}
//             localpath={localpath}
//             {...props}
//             appTitle="Quizzes4Leads"
//             appName="Quizzes4Leads"
//             facebookloginurl={facebookloginurl}
//             qzbasepath={qzbasepath}
//             registerUserUrl="https://www.socialmbuzz.com"
//             forgetPwdUrl="https://www.ndtv.com"
//             logourl="https://www.quizzes4leads.com"
//           />
//         )}
//       />
//     </BrowserRouter>,
//     document.getElementById("root")
//   );
// }


ReactDOM.hydrate(
        <BrowserRouter><Route
                path="/"
                component={props => (
                  <App
                    basepath={basepath}
                    localpath={localpath}
                    {...props}
                    appTitle="Quizzes4Leads"
                    appName="Quizzes4Leads"
                    facebookloginurl={facebookloginurl}
                    qzbasepath={qzbasepath}
                    registerUserUrl="https://www.socialmbuzz.com"
                    forgetPwdUrl="https://www.ndtv.com"
                    logourl="https://www.quizzes4leads.com"
                  />
                )}
              />
        </BrowserRouter>, document.getElementById('root'))
      
    
 
// ReactDOM.render(<BrowserRouter>

// <Route exact path="/" component={(props)=><App {...props} basepath={basepath}
// appTitle="Quizzes4Leads"
// appName="Quizzes4Leads"
// facebookloginurl = "https://www.facebook.com/dialog/oauth?client_id=3805076026252317&redirect_uri=http://test.socialmbuzz.com:45957/index.php/quizzer/fbauth/login&state=82e0a58fd9d3051f16d5c8e36c251ab8&sdk=php-sdk-3.2.3&scope=email,public_profile"
// qzbasepath={qzbasepath}
// registerUserUrl = "https://www.socialmbuzz.com"
// forgetPwdUrl="https://www.ndtv.com"
// logourl="https://www.quizzes4leads.com"
// />} />
// <Route exact path="/index.html" component={(props)=><App {...props} basepath={basepath}
// appTitle="Quizzes4Leads"
// appName="Quizzes4Leads"
// facebookloginurl = "https://www.facebook.com/dialog/oauth?client_id=3805076026252317&redirect_uri=http://test.socialmbuzz.com:45957/index.php/quizzer/fbauth/login&state=82e0a58fd9d3051f16d5c8e36c251ab8&sdk=php-sdk-3.2.3&scope=email,public_profile"
// qzbasepath={qzbasepath}
// registerUserUrl = "https://www.socialmbuzz.com"
// forgetPwdUrl="https://www.ndtv.com"
// logourl="https://www.quizzes4leads.com"
// />} />
// <Route path="/quizplayer.html" component={(props)=><Quizplayer {...props} basepath={basepath} />} />
// <Route path="/ForgetPassword" component={(props)=><ForgetPassword {...props} basepath={basepath} />} />
// <Route exact path="/Signup" component={(props)=> <UserRegistration {...props} basepath={basepath} />} />
// <PrivateRoute path="/home" render={()=> <QuizzerApp basepath={basepath} />} />
// <Route path="/QuizEditor/:id" component={()=> <TestQuizEditor />} />

// </BrowserRouter>
// , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

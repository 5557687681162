import React, { Component } from "react";
import { Link } from "react-router-dom";
import MultiPersonality from "./ResultScreens/MultiPersonality";
import PersonalityResult from "./ResultScreens/PersonalityResult";
import GradedResult from "./ResultScreens/GradedResult";
import LoadingScreen from "./ResultScreens/LoadingScreen";

class ResultShow extends Component {
  render() {
    const { quizData, restartQuiz, selectFont } = this.props;

    if (this.props.results.length === 0) {
      return (
        <>
          <LoadingScreen msg="Processing your responses" />
        </>
      );
    }
    const title = quizData.quiztitle.replace(/(<([^>]+)>)/gi, "");

    return (
      <>
        {this.props.results.quizType === "qztype7" && (
          <div className="row">
            <MultiPersonality
              results={this.props.results}
              theme_colors={quizData ? quizData.theme_colors : ""}
              quizofferbtnlink={quizData.quizofferbtnlink}
              quizofferbtntxt={quizData.quizofferbtntxt}
              retake_this_quiz_text={quizData.retake_this_quiz_text}
              is_enable_social_media={quizData.is_enable_social_media}
              enable_social_sharing_text={quizData.enable_social_sharing_text}
              title={title}
            />
          </div>
        )}
        {this.props.results.quizType === "qztype2" && (
          <div className="row">
            <PersonalityResult
              results={this.props.results}
              quizoffermsg={quizData.quizoffermsg}
              theme_colors={quizData ? quizData.theme_colors : ""}
              quizofferbtnlink={quizData.quizofferbtnlink}
              quizofferbtntxt={quizData.quizofferbtntxt}
              retake_this_quiz_text={quizData.retake_this_quiz_text}
              is_enable_social_media={quizData.is_enable_social_media}
              enable_social_sharing_text={quizData.enable_social_sharing_text}
              title={title}
              welcome_message_color={
                quizData ? quizData.welcome_message_color : ""
              }
            />
          </div>
        )}

        {this.props.results.quizType === "qztype1" && (
          <div className="row">
            <GradedResult
              results={this.props.results}
              result_page_custom_text={
                quizData ? quizData.result_page_custom_text : ""
              }
              quiztitle={quizData.quiztitle}
              theme_colors={quizData ? quizData.theme_colors : ""}
              quizofferbtnlink={quizData.quizofferbtnlink}
              quizofferbtntxt={quizData.quizofferbtntxt}
              retake_this_quiz_text={quizData.retake_this_quiz_text}
              restartQuiz={restartQuiz}
              is_enable_social_media={quizData.is_enable_social_media}
              enable_social_sharing_text={quizData.enable_social_sharing_text}
              title={title}
              welcome_message_color={
                quizData ? quizData.welcome_message_color : ""
              }
              selectFont={selectFont}
            />
          </div>
        )}
      </>
    );
  }
}

export default ResultShow;
